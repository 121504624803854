// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/channel/simulator',
    name: 'channel-simulator',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/channel/Simulador.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'channel_simulator',
    },
  },
  {
    path: '/channel/simulator/solways',
    name: 'channel-simulator-tarifarios-solways',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/channel/form/TarifariosSolways.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'channel_simulator',
    },
  },
]

export default formTable
